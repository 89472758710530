import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AllBike from "./screen/allbike";
import Bike from "./screen/bike";
import ContactUs from "./screen/contact_us";
import Home from "./screen/home";
import ReactGA from "react-ga";
import Sitemap from "./sitemap";
ReactGA.initialize("UA-126032534-1");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route
          path="/service"
          render={(props) => (
            <Service location={props.location.pathname.split("/")[2]} />
          )}
        /> */}
        <Route
          path="/bajaj-bikes"
          render={(props) => (
            <Bike location={props.location.pathname.split("/")[2]} />
          )}
        />
        <Route path="/all-bajaj-bikes" render={() => <AllBike />} />
        <Route path="/contactus" render={() => <ContactUs />} />
        <Route path="/sitemap_data" component={Sitemap} />
        <Route path="/" render={() => <Home />} />
        {/* <Route path="/admin" render={(props) => <Body {...props} />} /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
