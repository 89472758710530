import { kalyaniDark, loadingVid } from "../module/image_icon";

function LoadingPage() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <video width="150" loop={true} autoPlay={true} muted={true}>
          <source src={loadingVid} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

function Errorpage({ error }) {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <img alt="Img" width="100" src={kalyaniDark} />
        <div
          style={{
            fontSize: 18,
            display: "flex",
            justifyContent: "center",
            fontWeight: "600",
            margin: 20,
            color: "gray",
            flexDirection: "column",
          }}
        >
          Error
          <div style={{ fontWeight: "500", fontSize: 12 }}> {error}</div>
        </div>
      </div>
    </div>
  );
}

export { LoadingPage, Errorpage };
