const faq = [
  {
    title: "Where all Bajaj showroom Available?",
    desc: "Ans : We have at Mekhri Circle, Mathikere, Yelahanka, Devanahalli and Chikkaballapur",
  },
  {
    title: "Bajaj showrooms opening time?",
    desc: "Ans : Our Bajaj showrooms operation starts from 9 AM to 6.00 PM in week days and Sunday between 10am to 4pm.",
  },
  {
    title: "Where are Kalyani Bajaj service centers available?",
    desc: "Ans : We have 5 service centers available which are located at Mekhri Circle, Mathikere, Yelahanka, Devanahalli and Chikkaballapur. Click Here to know more: https://kalyanibajaj.com/contactus ",
  },
  {
    title: "Bajaj service center opening time?",
    desc: "Ans : Our Bajaj service centres operation starts from 8.30 AM to 6.00 PM in week days and Sunday between 10AM to 4PM.",
  },
  {
    title: "How do I book new bike?",
    desc: "Ans : You can book the vehicle via  Online payment by scanning our QR code, G-Pay or PhonePe. ",
  },
  {
    title: "What documents required for booking?",
    desc: "Ans : We just required your KYC documents for processing of booking",
  },
  {
    title: "What is the rate of Interest for Two wheeler?",
    desc: "Ans : Rate of interest starts from 8% further depends on the individual profile.",
  },
  {
    title: "How many days will it take for Loan process?",
    desc: "Ans : Loan will be processed within an hour, if the documents and profile are matching as per the requirement.",
  },
  {
    title: "How safe is that when we transfer the amount via G-Pay?",
    desc: "Ans : Amount will be transferred by adding company details through QR Code scanner.",
  },
  {
    title: "Which are the fast moving in Bajaj models?",
    desc: "Ans : Most of the Bajaj bikes are fast moving depending upon the customer choice as Mileage-Budget, sports & cruiser.",
  },
  {
    title: "How best is the Bajaj models when compared?",
    desc: "Ans : Individual person can go for Bajaj models as it has more power, less maintenance and best mileage. ",
  },
  {
    title:
      "What is the unique features when compared to another brand of 110 CC’s? ",
    desc: "Ans :  For all our above 110 cc’s we have Twin Spark implemented.",
  },
];

const testimonials = [
  {
    user: "Ruchi Prayana",
    desc: "Positive: Professionalism, quality,Value My overall experience for Bike mechanic was really good and satisfying. The service I got was very polite and helpful. One who require genuine service should definitely go and visit Banashankari Bajaj Service for the best. All the technician all great helpfull. The technician shreyas was very helpful. Thank Bajaj Banashankari",
  },
  {
    user: "PAVAN",
    desc: "Highly interactive technicians, good service. My Pulser200 has coolent oil leakage problem form past 6months... Some one sugested Banashankari service station. After visiting many Bajaj service center. Finally the problem has been cleared by visiting just once to Banashankari Bajaj service center. Thanks for technicians there, majorly thanks to shreys (technician).",
  },
  {
    user: "Vikas V",
    desc: "Good service and friendly guys. They explain the problem and solve it. Vijay is very helpful. Any query about the bike anytime, he picks the calls and helps out.",
  },
  {
    user: "Manisuriya Suriya",
    desc: "Good response from sales team , Saturday I visited the showroom to enquiry even they offered for test drive , so I refer to visit the showesho for the customers best service ever .",
  },
  {
    user: "Yashvanth Vk",
    desc: "There was gud response from the term , and I refer customers to visit to the showroom .my brother took  the bike  they gave with registration  and ful documents , if you are planing to to buy  u guys sould visit Bajaj Banashankari  for the gud response.",
  },
  {
    user: "Lakshminarayanan Munesh",
    desc: "I'm new to the Bajaj family. I have got the first service done in this service center. Senior technician Mr Manju is very knowledgeable, did a great job with the bike. They also let you in to see the service process. Overall it's a very good place for your Bajaj service.",
  },
  {
    user: "Shreyas MB",
    desc: "I got my Pulser390 serviced on Saturday 14th August and the bike was delivered as promised on the same day and all my requests were attended and I could see the difference after service to before. Also Shreyas was the person who took the service request and he lived up to my expectation, also as promised he even called me today to inform me about software update. I definitely recommend this place for other Bajaj lovers to visit for servicing their Bajaj bikes.",
  },
  {
    user: "Harshavardhana S G",
    desc: "Bajaj is love ❤️. So Bajaj showroom is also a lovely place. Vry good staff is available here. Good customer satisfaction is given to each and every customer. Vry good service of the bike is done here. All the problems are solved without any hassles.",
  },
  {
    user: "Madan Raj",
    desc: "Good service, Mr Mahesh is definitely among the best & honest technician I have seen so far.. Even sales team ks good with their knowledge and customer gesture.. I suggest anyone to contact them before taking their bike for service as they would be busy most of the time..",
  },
  {
    user: "Aggit Kurian",
    desc: "Amazing experience in Bajaj showroom, nice and caring.  Well trained people's are use to service bikes in this place.",
  },
  {
    user: "Jagadish Kumar",
    desc: "Excellent customer satisfaction both in sales as well as service.Kindly head down here for any enquiries as well as test drives and quotations.Good service by sales team by offering a variety of finance schemes and after sales support",
  },
  {
    user: "Kiran Robert",
    desc: "From past 4 years I service my Bajaj Pulserhere, it's always a wonderful experience, special thanks to Venkatesh for all the support💪 Like",
  },
  {
    user: "Vinod Chandra",
    desc: "Good one for service of Bajaj bike I just done with Bajaj Pulser200 service pretty good they have done the service.",
  },
  {
    user: "Naveen Pradeep Pandurangan",
    desc: "I've always got my Pulser390 serviced from here. They do a really good job and the staff are courteous and listen to your problem and put best efforts in solving the problem. Thank you guys keep up the good work!!!",
  },
  {
    user: "Sreedhar Sree",
    desc: "Satisfied with the service and the response to customers. Met Mr. venkatesh, who has taken my Adv 390 for service. Thanks alot team.",
  },
];

const reviews = [
  {
    user: "Ruchi Prayana",
    raiting: 5,
    desc: "Positive: Professionalism, quality,Value My overall experience for Bike mechanic was really good and satisfying. The service I got was very polite and helpful. One who require genuine service should definitely go and visit Banashankari Bajaj Service for the best. All the technician all great helpfull. The technician shreyas was very helpful. Thank Bajaj Banashankari",
    location: "Bajaj Banashankari",
  },
  {
    user: "PAVAN",
    raiting: 5,
    desc: "Highly interactive technicians, good service. My Pulser200 has coolent oil leakage problem form past 6months... Some one sugested Banashankari service station. After visiting many Bajaj service center. Finally the problem has been cleared by visiting just once to Banashankari Bajaj service center. Thanks for technicians there, majorly thanks to shreys (technician).",
    location: "Bajaj Banashankari",
  },
  {
    user: "Vikas V",
    raiting: 5,
    desc: "Good service and friendly guys. They explain the problem and solve it. Vijay is very helpful. Any query about the bike anytime, he picks the calls and helps out.",
    location: "Bajaj Banashankari",
  },
  {
    user: "Manisuriya Suriya",
    raiting: 5,
    desc: "Good response from sales team , Saturday I visited the showroom to enquiry even they offered for test drive , so I refer to visit the showesho for the customers best service ever .",
    location: "Bajaj Banashankari",
  },
  {
    user: "Yashvanth Vk",
    raiting: 5,
    desc: "There was gud response from the term , and I refer customers to visit to the showroom .my brother took  the bike  they gave with registration  and ful documents , if you are planing to to buy  u guys sould visit Bajaj Banashankari  for the gud response.",
    location: "Bajaj Banashankari",
  },
  {
    user: "Lakshminarayanan Munesh",
    raiting: 5,
    desc: "I'm new to the Bajaj family. I have got the first service done in this service center. Senior technician Mr Manju is very knowledgeable, did a great job with the bike. They also let you in to see the service process. Overall it's a very good place for your Bajaj service.",
    location: "Bajaj Kengeri",
  },
  {
    user: "Shreyas MB",
    raiting: 5,
    desc: "I got my Pulser390 serviced on Saturday 14th August and the bike was delivered as promised on the same day and all my requests were attended and I could see the difference after service to before. Also Shreyas was the person who took the service request and he lived up to my expectation, also as promised he even called me today to inform me about software update. I definitely recommend this place for other Bajaj lovers to visit for servicing their Bajaj bikes.",
    location: "Bajaj Kengeri",
  },
  {
    user: "Harshavardhana S G",
    raiting: 5,
    desc: "Bajaj is love ❤️. So Bajaj showroom is also a lovely place. Vry good staff is available here. Good customer satisfaction is given to each and every customer. Vry good service of the bike is done here. All the problems are solved without any hassles.",
    location: "Bajaj Kengeri",
  },
  {
    user: "Madan Raj",
    raiting: 5,
    desc: "Good service, Mr Mahesh is definitely among the best & honest technician I have seen so far.. Even sales team ks good with their knowledge and customer gesture.. I suggest anyone to contact them before taking their bike for service as they would be busy most of the time..",
    location: "Bajaj Kengeri",
  },
  {
    user: "Aggit Kurian",
    raiting: 5,
    desc: "Amazing experience in Bajaj showroom, nice and caring.  Well trained people's are use to service bikes in this place.",
    location: "Bajaj Kengeri",
  },
  {
    user: "Jagadish Kumar",
    raiting: 5,
    desc: "Excellent customer satisfaction both in sales as well as service.Kindly head down here for any enquiries as well as test drives and quotations.Good service by sales team by offering a variety of finance schemes and after sales support",
    location: "Bajaj Yelahanka",
  },
  {
    user: "Kiran Robert",
    raiting: 5,
    desc: "From past 4 years I service my Bajaj Pulserhere, it's always a wonderful experience, special thanks to Venkatesh for all the support💪 Like",
    location: "Bajaj Yelahanka",
  },
  {
    user: "Vinod Chandra",
    raiting: 5,
    desc: "Good one for service of Bajaj bike I just done with Bajaj Pulser200 service pretty good they have done the service.",
    location: "Bajaj Yelahanka",
  },
  {
    user: "Naveen Pradeep Pandurangan",
    raiting: 5,
    desc: "I've always got my Pulser390 serviced from here. They do a really good job and the staff are courteous and listen to your problem and put best efforts in solving the problem. Thank you guys keep up the good work!!!",
    location: "Bajaj Yelahanka",
  },
  {
    user: "Sreedhar Sree",
    raiting: 5,
    desc: "Satisfied with the service and the response to customers. Met Mr. venkatesh, who has taken my Adv 390 for service. Thanks alot team.",
    location: "Bajaj Yelahanka",
  },
];

export { faq, testimonials, reviews };
