import React, { useEffect, useState } from "react";
import Header from "../compnent/header";
import HomeFooter from "../compnent/home_footer";
import { Errorpage, LoadingPage } from "../compnent/loading_error";
import { EnquiryPopup } from "../compnent/popups";
import { getAllcontact } from "../method/contactus";
import { setUrlimgLoc } from "../module/api_init";
import { directIcon, globeIcon, phoneIconB } from "../module/image_icon";

function ContactUs() {
  const [showEnquiry, setshowEnquiry] = useState(-1);
  const [page, setpage] = useState(0);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");
  const [locations, setlocations] = useState([]);

  useEffect(() => getAllcontact(setlocations, setloading, seterror), []);

  return loading ? (
    <LoadingPage />
  ) : error !== "" ? (
    <Errorpage error={error} />
  ) : (
    <React.StrictMode>
      <EnquiryPopup
        type={showEnquiry}
        close={() => setshowEnquiry(-1)}
        remove={() => {}}
        bike=""
      />
      <Header />
      <div className="bk1_bh1_z">
        <div className="bk1_bh1_a">
          <div className="bk1_bh1_b">
            {locations.map((loc, k) => (
              <div onClick={() => setpage(k)} key={k}>
                <div className="bk1_bh1_ba">{loc.brand_name}</div>
                <div
                  className="bk1_bh1_bb"
                  style={k === page ? {} : { visibility: "hidden" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {locations[page].locations.map((loc) => (
        <div className="center">
          <div className="cu1_lc1_a">
            <div className="cu1_lc1_ab">
              {/* <Carousel> */}
              {/* {loc.images.map((img) => ( */}
              <img
                alt="Img"
                className="cu1_lc1_ab"
                src={setUrlimgLoc(loc.images[0].file)}
                // src="https://lh5.googleusercontent.com/p/AF1QipNF_zmkEaexh0pqqgWNsbjI27n4PU-32Do9NZJX=w480-h240-k-no"
              />
              {/* ))} */}
              {/* </Carousel> */}
            </div>
            <div className="cu1_lc1_ac">
              <div className="cu1_lc1_acba">{loc.location_name}</div>
              <div className="cu1_lc1_acbb">{loc.address}</div>
              <div className="cu1_lc1_acbba">
                {loc.phone}/{loc.alternate_phone}
              </div>
              <div className="cu1_lc1_acbba">
                customersupport@kalyanimotors.com
              </div>
              <div className="cu1_lc1_acbba">
                Opening Time {loc.open_time}&nbsp;&nbsp;&nbsp;&nbsp;Closing Time{" "}
                {loc.close_time}
              </div>

              <div className="cu1_lc1_aca">
                <div
                  className="cu1_lc1_acaa"
                  onClick={() =>
                    window.location.assign(
                      `https://maps.google.com/?q=${loc.latitude},${loc.longtitude}`
                    )
                  }
                >
                  <img alt="Img" className="cu1_lc1_acab" src={directIcon} />
                  Direct
                </div>
                <div
                  className="cu1_lc1_acaa"
                  onClick={() => window.location.assign(`tel:${loc.phone}`)}
                >
                  <img alt="Img" className="cu1_lc1_acab" src={phoneIconB} />
                  Call
                </div>
                {/* <div
                  className="cu1_lc1_acaa"
                  onClick={() => window.location.assign(loc.website)}
                >
                  <img alt="Img" className="cu1_lc1_acab" src={globeIcon} />
                  Web Site
                </div> */}
                <div className="cu1_lc1_acac">
                  <div
                    className="cu1_lc1_acaca"
                    onClick={() => setshowEnquiry(3)}
                  >
                    Book a Test Drive
                  </div>
                  <div
                    className="cu1_lc1_acaca"
                    onClick={() => setshowEnquiry(4)}
                  >
                    Book a Service
                  </div>
                  <div
                    className="cu1_lc1_acaca"
                    onClick={() => setshowEnquiry(5)}
                  >
                    Enquire Now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <HomeFooter bikes={null} popup={(v) => setshowEnquiry(v)} />
    </React.StrictMode>
  );
}

export default ContactUs;
