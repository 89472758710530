import { makespaceline } from "../module/simple";

function BikeColor({ colors, setcolor, cColor, setimgcount }) {
  return (
    <div className="center" id="colours">
      <div className="bk2_cl1_z">
        <div className="cm1_bk_subtitle">{colors.title} Colours</div>
        <div className="bk2_cl1_a">
          {colors.products.map((color, k) => (
            <div className="bk2_cl1_aa" key={k}>
              <div
                onClick={() => {
                  setimgcount(0);
                  setcolor(k);
                  console.log(colors.products[k].name)
                  window.history.replaceState(
                    null,
                    "",
                    `/bajaj-bikes/${colors.products[k].route}/${makespaceline(
                      colors.products[k].name
                    )}`
                  );
                }}
                className="bk2_cl1_aaa"
                style={{
                  background: color.colors[0].colour_code,
                  borderWidth: k === cColor ? 3 : "",
                }}
              />
              <div
                className="bk2_cl1_aab"
                style={{ fontWeight: k === cColor ? "bold" : "" }}
              >
                {color.colors[0].colour_name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BikeColor;
