import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./style/hm1.css";
import "./style/hm2.css";
import "./style/hm3.css";
import "./style/bk1.css";
import "./style/bk2.css";
import "./style/cm1.css";
import "./style/ab1.css";
import "./style/cu1.css";
import "./style/mobile.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
