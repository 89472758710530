// export const baseUrl2 = "https://bajajapi.escvpn.com/api/";
// const baseUrlimg = "https://alphakalyani.escvpn.com/";
// const baseUrlimgloc = "https://bajajapi.escvpn.com/storage/";
// const baseUrllogo = "https://bajajadmin.escvpn.com/uploads/Logo/";

const baseUrl = "https://bajajapi.kalyaniktm.com/api/";
const baseUrlimg = "https://bajajadmin.kalyaniktm.com/";
const baseUrlimgloc = "https://bajajapi.kalyaniktm.com/storage/";
const baseUrllogo = "https://bajajadmin.kalyaniktm.com/uploads/Logo/";

const setUrlimg = (v) => baseUrlimg + v;
const setUrlimgLoc = (v) => baseUrlimgloc + v.split("/")[1];
// const setUrlimg = (v) => baseUrlimg + v.split("/")[1];

export { baseUrl, baseUrlimg, setUrlimg, baseUrllogo, setUrlimgLoc };
