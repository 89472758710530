import { Carousel } from "react-responsive-carousel";
import { brandLogoD, kalyaniDark, phoneIcon } from "../module/image_icon";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";

function HomeTop({ bg, banner }) {
  return (
    <React.StrictMode>
      <div className="hm1_tb1_1a">
        <Carousel
          swipeable={true}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={2000}
          showStatus={false}
          showArrows={window.innerWidth < 600 ? false : true}
        >
          {banner.map((ban, k) => (
            <img alt="Img" key={k} src={ban} />
          ))}
        </Carousel>
      </div>
      <div
        className="hm1_tp1_a"
        style={bg ? { margin: 0, borderRadius: 0 } : {}}
      >
        <img alt="img" className="hm1_tp1_c" src={kalyaniDark} />
        <div className="hm1_tp1_d"></div>
        <div className="hm1_tp1_f">
          <div
            className="hm1_tp1_e"
            onClick={() => window.location.assign("/all-bajaj-bikes")}
          >
            ALL BAJAJ BIKES
          </div>

          <div
            className="hm1_tp1_g"
            onClick={() => window.location.assign("tel:8095699900")}
          >
            <img alt="Img" src={phoneIcon} width="15" /> &nbsp;&nbsp;80956 99900
          </div>

          <img
            alt="img"
            onClick={() => window.location.assign("/")}
            className="hm1_tp1_h"
            src={brandLogoD}
          />
        </div>
      </div>

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-126032534-1"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-126032534-1');`}
      </script>

      <script type="text/javascript">
        {`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/6166cf0786aee40a57365a26/1fhss1j4q';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();`}
      </script>
    </React.StrictMode>
  );
}

export default HomeTop;
