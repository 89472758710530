import React, { useEffect } from "react";
import {
  facebookIcon,
  instagramIcon,
  whatsappIcon,
} from "../module/image_icon";
import bikeIcon from "../asset/motorcycle.png";
import phoneWhite from "../asset/phonewhite.png";
import pinLocate from "../asset/pinLocate.png";
import priceTag from "../asset/price-tag.png";
import ReactGA from "react-ga";
ReactGA.initialize("UA-126032534-1");

function HomeFooter({ bikes, popup }) {
  const socialicons = [
    {
      icon: facebookIcon,
      link: "https://www.facebook.com/KalyaniBajajblr",
    },
    { icon: instagramIcon, link: "https://www.instagram.com/kalyani.bajaj/" },

    {
      icon: whatsappIcon,
      link: "https://api.whatsapp.com/send?phone=919513641416",
    },
  ];

  useEffect(() => {
    ChartSupport();
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const ChartSupport = () => {
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/6166cf0786aee40a57365a26/1fhss1j4q";
    script.async = true;
    document.body.appendChild(script);
  };

  return (
    <React.StrictMode>
      <div className="hm1_bt1_a">
        <div className="hm1_bt1_c">
          <div className="hm1_bt1_d">HOME</div>
          <div
            onClick={() => window.location.assign(`/`)}
            className="hm1_bt1_da"
          >
            HOME
          </div>
        </div>
        {bikes !== null ? (
          <div className="hm1_bt1_c">
            <div className="hm1_bt1_d"> BAJAJ BIKES </div>
            {bikes[0].bikes[0].map((bike, k) => (
              <div
                key={k}
                onClick={() =>
                  window.location.assign(`/bajaj-bikes/${bike.route}`)
                }
                className="hm1_bt1_da"
              >
                {bike.name}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        <div className="hm1_bt1_c">
          <div className="hm1_bt1_d"> SERVICE BOOKING {"&"} TEST RIDE</div>
          <div className="hm1_bt1_da" onClick={() => popup(3)}>
            BOOK A TEST RIDE
          </div>
          <div className="hm1_bt1_da" onClick={() => popup(4)}>
            ONLINE SERVICE BOOKING
          </div>
        </div>
        <div className="hm1_bt1_c">
          <div className="hm1_bt1_d">ABOUT KALYANI BAJAJ</div>
          <div
            className="hm1_bt1_da"
            onClick={() => window.location.assign(`/contactus`)}
          >
            ABOUT US
          </div>
          {/* <div className="hm1_bt1_da">CAREERS</div> */}
        </div>
        <div className="hm1_bt1_c">
          <div
            className="hm1_bt1_d"
            onClick={() => window.location.assign("/contactus")}
          >
            CONTACT US
          </div>
          <div
            className="hm1_bt1_da"
            onClick={() => window.location.assign("/contactus")}
          >
            SALES
          </div>
          <div
            className="hm1_bt1_da"
            onClick={() => window.location.assign("/contactus")}
          >
            SERVICE
          </div>
          {/* <div className="hm1_bt1_da">FEEDBACK</div> */}
        </div>
      </div>
      <div className="hm1_bt1_b">
        {socialicons.map((i, k) => (
          <img
            alt="Img"
            key={k}
            height="25"
            style={{ margin: "0px 10px" }}
            onClick={() => window.location.assign(i.link)}
            src={i.icon}
          />
        ))}
      </div>
      <div className="hm1_bt1_b">
        © 2016 KALYANI BAJAJ, ALL RIGHTS RESERVED.
      </div>
      <div className="hm1_bt1_c1">
        <div
          className="hm1_bt1_c1a"
          onClick={() => window.location.assign("/contactus")}
        >
          <img src={pinLocate} height="18" className="hm1_bt1_c1aa" />
          Locate Us
        </div>

        <div
          className="hm1_bt1_c1a"
          onClick={() => window.location.assign("/all-bajaj-bikes")}
        >
          <img src={bikeIcon} height="25" className="hm1_bt1_c1aa" />
          BAJAJ Bikes
        </div>

        {/* <div
          className="hm1_bt1_c1a"
          onClick={() => window.location.assign("https://wa.me/+919544013463")}
        >
          <img src={whatsappIcon} height="20" className="hm1_bt1_c1aa" />
          Whatsapp
        </div> */}
        <div className="hm1_bt1_c1a" onClick={() => popup(0)}>
          <img src={priceTag} height="20" className="hm1_bt1_c1aa" />
          On-Road Price
        </div>
        <div
          className="hm1_bt1_c1a"
          onClick={() => window.location.assign("tel:8095699900")}
        >
          <img src={phoneWhite} height="18" className="hm1_bt1_c1aa" />
          Call Now
        </div>
      </div>
    </React.StrictMode>
  );
}

export default HomeFooter;
