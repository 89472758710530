import { brandLogoD, kalyaniDark, phoneIcon } from "../module/image_icon";

function Header() {
  return (
    <div className="cm1_hd1_a">
      <img
        alt="img"
        className="hm1_tp1_c"
        onClick={() => window.location.assign("/")}
        src={kalyaniDark}
      />

      <div className="hm1_tp1_f">
        <div className="hm1_tp1_g">
          <img alt="Img" src={phoneIcon} width="15" /> &nbsp;&nbsp;80956 99900
        </div>
        <img
          alt="img"
          className="hm1_tp1_h"
          onClick={() => window.location.assign("/")}
          src={brandLogoD}
        />
      </div>
    </div>
  );
}

export default Header;
