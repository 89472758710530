import React from "react";

function BikeSummery({ bike }) {

  return (
    <div className="center" id="summery">
      <div className="bk1_sm_a">
        <div className="cm1_bk_subtitle">{bike.name} Summary</div>
        {/* <div className="bk1_sm_ac">{bike.name} highlights</div>
        <div className="bk1_sm_ad">
          {items.map((item, k) => (
            <React.StrictMode key={k}>
              <div className="bk1_sm_ada">
                <div className="bk1_sm_adaa">{item.title}</div>
                <div className="bk1_sm_adab">{item.desc}</div>
              </div>
              {items.length - 1 !== k ? <div className="bk1_sm_adb" /> : ""}
            </React.StrictMode>
          ))}
        </div>
        <div className="bk1_sm_ae" /> */}
        {/* <div className="cm1_bk_subtitle">About {bike.name}</div> */}
        <div className="bk1_sm_af">{bike.description}</div>
      </div>
    </div>
  );
}

export default BikeSummery;
