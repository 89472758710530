import axios from "axios";
import { baseUrl } from "../module/api_init";
import { makelinespace } from "../module/simple";
import { getAllBikes } from "./home";

async function getBikedetails(setstate) {
  var loc = window.location.pathname.split("/");
  await axios
    .get(`${baseUrl}varientbike?name=${loc[2]}`)
    .then((res) => {
      if (res.data.length === 0) setstate({ error: "No Data" });
      else {
        if (loc[3] !== undefined && loc[3] !== "") {
          for (let i = 0; i < res.data[0].varients.length; i++) {
            var v = res.data[0].varients[i];
            for (let j = 0; j < v.products.length; j++) {
              if (v.products[j].name === makelinespace(loc[3])) {
                v.products.unshift(v.products[j]);
                v.products.splice(j + 1, 1);
                res.data[0].varients.unshift(v);
                res.data[0].varients.splice(i + 1, 1);
              }
            }
          }
        }
        setstate({ bike: res.data });
      }
    })
    .catch(() => setstate({ error: "Not Found" }));
}

const executeScroll = (id, k, setpage) => {
  setpage(k);
  var element = document.getElementById(id);
  var elementPosition = element.getBoundingClientRect().top;
  window.scrollTo({
    top: window.scrollY + elementPosition - 100,
    behavior: "smooth",
  });
};

async function getAll(setstate) {
  setstate({ loading: true });
  await getBikedetails(setstate);
  await getAllBikes(setstate);
  setstate({ loading: false });
}

export { getBikedetails, executeScroll, getAll };
