import React from "react";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { setUrlimg } from "../module/api_init";
import { checkAnim, tickIcon } from "../module/image_icon";
import { getOnrodePrice } from "../module/methord";

function EnquiryPopup({ type, close, remove, bike }) {
  const [booked, setbooked] = useState(0);
  const types = [
    "Get On-road Price",
    "Get New Offer",
    "Get EMI Offer",
    "BOOK A TEST RIDE",
    "ONLINE SERVICE BOOKING",
    "Enquiry",
  ];
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: type !== -1 ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0,.5)",
      }}
    >
      <div className="hm2_left-div">
        {booked === 0 ? (
          <div className="cm1_hm_title">{types[type]}</div>
        ) : (
          <></>
        )}
        {booked !== 0 ? (
          booked === 1 ? (
            <img
              alt="Img"
              style={{ padding: "20px 103px", width: 100 }}
              src={checkAnim}
            />
          ) : (
            <img
              alt="Img"
              style={{ padding: "20px 103px", width: 100 }}
              src={tickIcon}
            />
          )
        ) : (
          <form
            id="booking_form"
            onSubmit={(e) => {
              getOnrodePrice(
                e,
                () => {},
                () => {
                  setbooked(1);
                  setTimeout(() => setbooked(2), 1700);
                  setTimeout(() => close(), 3000);
                },
                types[type],
                bike
              );
              remove();
            }}
          >
            <input
              placeholder="Name"
              className="hm2_input_tab"
              type="text"
              required
              id="name"
            />
            <input
              placeholder="Phone Number"
              className="hm2_input_tab"
              type="text"
              required
              id="id"
            />

            <button type="submit" className="hm2_btn">
              {types[type]}
            </button>
          </form>
        )}
        {booked !== 0 ? (
          <p
            style={{
              padding: "0px 4px",
              fontSize: 14,
              color: "#4d5057",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Thank you for your intrest in {bike} We will get in touch with you
            soon...
          </p>
        ) : (
          ""
        )}
        <div
          onClick={() => close()}
          style={{ paddingBottom: 10 }}
          className="cm1_btn_a"
        >
          Close
        </div>
      </div>
    </div>
  );
}

function BikePopup({ show, imgs, close }) {
  return (
    <div
      style={{
        width: "100vw",
        display: show ? "flex" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: 800,
          width: "100%",
          display: show ? "" : "none",
          position: "fixed",
          // border: "1px solid gray",
          boxShadow: "1px 1px 4px gray",
          background: "white",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 22,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              padding: "10px 20px",
              paddingBottom: 2,
            }}
            onClick={close}
          >
            X
          </div>
        </div>
        <Carousel useKeyboardArrows>
          {imgs.map((img, k) => (
            <img
              key={k}
              alt="Img"
              src={setUrlimg(img)}
              style={{
                maxWidth: 700,
                width: "100%",
                objectFit: "cover",
                border: "1px solid #ccc",
                borderRadius: 8,
              }}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export { EnquiryPopup, BikePopup };
