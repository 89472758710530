import React, { Component } from "react";
import { getAllbike } from "./method/allbikes";

export default class Sitemap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    getAllbike(
      (v) => {
        for (let i = 0; i < v.length; i++) {
          {
            this.state.items.push(
              <>
                <br />
                {"<url>"}
                <br />
                {`<loc>https://kalyanibajaj.com/bajaj-bikes/${v[i].route}</loc>`}
                <br />
                {"<lastmod>2021-10-03T12:33:12+00:00</lastmod>"}
                <br />
                {"<priority>0.80</priority>"}
                <br />
                {"</url>"}
              </>
            );
            this.setState({ items: this.state.items });
          }
        }
      },
      () => {},
      () => {}
    );
  }

  render() {
    var xml_data = (
      <>
        {'<?xml version="1.0" encoding="utf-8" standalone="yes" ?>'}
        <br />
        {'<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">'}
        <br />
        {"<url>"}
        <br />
        {"<loc>https://kalyanibajaj.com/</loc>"}
        <br />
        {"<lastmod>2021-10-03T12:33:12+00:00</lastmod>"}
        <br />
        {"<priority>1.00</priority>"}
        <br />
        {"</url>"}
        <br />
        {"<url>"}
        <br />
        {"<loc>https://kalyanibajaj.com/all-bajaj-bikes</loc>"}
        <br />
        {"<lastmod>2021-10-03T12:33:12+00:00</lastmod>"}
        <br />
        {"<priority>1.00</priority>"}
        <br />
        {"</url>"}
        <br />
        {"<url>"}
        <br />
        {"<loc>https://kalyanibajaj.com/contactus</loc>"}
        <br />
        {"<lastmod>2021-10-03T12:33:12+00:00</lastmod>"}
        <br />
        {"<priority>1.00</priority>"}
        <br />
        {"</url>"}
        <br />
        {"<url>"}
        <br />
        {"<loc>https://kalyanibajaj.com/contactus</loc>"}
        <br />
        {"<lastmod>2021-10-03T12:33:12+00:00</lastmod>"}
        <br />
        {"<priority>1.00</priority>"}
        <br />
        {"</url>"}
        {this.state.items.map((item) => item)}
        <br />
        {"</urlset>"}
        <br />
      </>
    );

    return xml_data;
  }
}

function sortBids(bids) {}
